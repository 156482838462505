import { useContext } from 'react';

import { I18nContext } from 'common/useT';

interface TripLongIdlingFilterProps {
  onChange?: (checked: boolean) => void;
  disabled: boolean;
}

export const TripLongIdlingFilter = ({ onChange, disabled }: TripLongIdlingFilterProps) => {
  const i18nContext = useContext(I18nContext);

  const { tSafe } = i18nContext ?? {};

  if (!tSafe) return null;

  return (
    <div className="flex-[.5] p-1 bg-gray-100 rounded-8 ml-1">
      <div className="flex flex-col">
        <div className="flex">
          <span>
            {tSafe('components.ActivityHistoryList.TripLongIdlingFilter.heading', {
              defaultValue: 'Only show trips with long idling',
            })}
          </span>

          <input
            type="checkbox"
            defaultChecked={false}
            disabled={disabled}
            onChange={(e) => onChange?.(e.target.checked)}
          />
        </div>
      </div>
    </div>
  );
};

export default TripLongIdlingFilter;
