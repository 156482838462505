import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { dashboardLightsIconMap } from 'common/iconMaps';
import { I18nContext } from 'common/useT';
import Widget, { WidgetPageType } from 'components/Dashboard/Widget';
import { DashboardLightMessageType } from 'generated/graphql';
import { entries } from 'utils';

import { useVehicleDetails } from '../hooks';
import { DashboardLightIcon } from './DashboardLightIcon';
import Tooltip from 'atoms/Tooltip';
export const title = 'Dashboard Lights';

const DashboardLights = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, error, data: [vehicleDetails] = [] } = useVehicleDetails();

  if (loading || !vehicleDetails || !i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      enums: { dashboardLightMessageTypeDescriptionMap },
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div>{error_text}</div>;

  const { activeDashboardLights } = vehicleDetails;

  const icons = entries(DashboardLightMessageType).reduce((acc, [value, dlType]) => {
    const isActive = !!activeDashboardLights.find((dl) => dl.type === dlType);
    const icon = (
      <Tooltip text={dashboardLightMessageTypeDescriptionMap[dlType]} key={value}>
        <DashboardLightIcon warning={isActive} description={value} iconName={dashboardLightsIconMap[dlType]} />
      </Tooltip>
    );

    if (isActive) {
      acc.unshift(icon);
    } else {
      acc.push(icon);
    }
    return acc;
  }, [] as JSX.Element[]);

  return (
    <Widget
      title={tSafe('components.Vehicle.Detail.DashboardLights.title', { defaultValue: 'Dashboard Lights' })}
      warning={!!activeDashboardLights.length}
      collapsible
      page={WidgetPageType.Vehicle}
    >
      <div className="relative mt-1 h-full p-1 w-full bg-white overflow-hidden" data-testid="dashboard-lights">
        <div className="grid grid-cols-6 -mt-2 gap-4 items-center p-1 overflow-y-auto">{icons}</div>

        {!activeDashboardLights.length && (
          <div>
            <div className="absolute top-[54px] left-0 right-0 mx-auto flex flex-center justify-center">
              <div className="mr-1 w-3">
                <Icon name="connected" className="text-success text-md" aria-label="Connected" />
              </div>

              <div>
                {tSafe('components.Vehicle.Detail.DashboardLights.no-active-dashboard-lights', {
                  defaultValue: 'No Active Dashboard Lights',
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </Widget>
  );
};

export default DashboardLights;
