import { endOfDay } from 'date-fns';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { periodStartToDate } from 'common/periodHelpers';
import { I18nContext } from 'common/useT';
import { ActivityHistoryList } from 'components/ActivityHistoryList';
import { ActivityHistoryDateFilterOption, DateFilter } from 'components/ActivityHistoryList/DateFilter';
import { EventTypeFilter } from 'components/ActivityHistoryList/EventTypeFilter';
import { activityHistoryTripIds } from 'components/ActivityHistoryList/EventTypes/Details/TripEventDetail/state';
import GeofenceBreachFilter from 'components/ActivityHistoryList/GeofenceBreachFilter';
import { ListSort } from 'components/ActivityHistoryList/ListSort';
import TripLongIdlingFilter from 'components/ActivityHistoryList/TripLongIdlingFilter';
import { useCurrentDriverId, useDriverDetails } from 'components/Driver/Detail/hooks';
import {
  ActivityHistorySortType,
  DriverActivityHistoryIncludeType,
  DriverGeofenceBreachEvent,
  DriverTripEvent,
  GetDriverActivityHistoryDoc,
  GetFleetGeofencesDoc,
  LongIdleEvent,
  Period,
} from 'generated/graphql';
import { SelectOption } from 'types';
import { GeofenceBreachFilterType } from 'types/activityHistory';
import { DriverActivityEventItem, DriverActivityEventType } from 'types/driverActivityHistory';
import { arrayDedupeOnKey, isDefined, values } from 'utils';
import { useQ } from 'utils/apolloClient';

const maxItemsPerPage = 100;
const defaultPeriod = Period.P7d;

const eventTypeToIncludeTypeMap: Record<DriverActivityEventType, DriverActivityHistoryIncludeType> = {
  disconnected: DriverActivityHistoryIncludeType.DeviceDisconnectEvents,
  reconnected: DriverActivityHistoryIncludeType.DeviceReconnectEvents,
  impact: DriverActivityHistoryIncludeType.ImpactEvents,
  trip: DriverActivityHistoryIncludeType.TripEvents,
};

const getGeofenceIdFilter = (state: GeofenceBreachFilterType | string, allGeofenceIds: string[]) => {
  if (state === GeofenceBreachFilterType.NONE) return undefined;
  if (state === GeofenceBreachFilterType.ALL) return allGeofenceIds;

  return [state];
};

export const ActivityHistoryPanel = () => {
  const i18nContext = useContext(I18nContext);
  const setTripIds = useSetRecoilState(activityHistoryTripIds);
  const defaultDateFrom = periodStartToDate(defaultPeriod);
  const [eventTypeFilterState, setEventTypeFilterState] = useState(values(DriverActivityEventType));
  const [periodFilterState, setPeriodFilterState] = useState<Period | undefined>(defaultPeriod);
  const [dateFromFilterState, setDateFromFilterState] = useState<Date | undefined>(defaultDateFrom);
  const [dateToFilterState, setDateToFilterState] = useState<Date | undefined>(endOfDay(new Date()));
  const [sortTypeState, setSortTypeState] = useState(ActivityHistorySortType.NewestFirst);
  const [geofenceBreachFilterState, setGeofenceBreachFilterState] = useState<GeofenceBreachFilterType | string>(
    GeofenceBreachFilterType.NONE,
  );
  const [tripLongIdlingFilterState, setTripLongIdlingFilterState] = useState<boolean>(false);
  const [currentPageOffsetState, setCurrentPageOffsetState] = useState<number[]>([]);
  const resetPageOffsets = () => setCurrentPageOffsetState([]);
  const currentPageOffset = currentPageOffsetState.reduce((a, b) => a + b, 0);

  const driverId = useCurrentDriverId();
  const { loading: driverDetailsLoading, data: [driverDetails] = [] } = useDriverDetails();
  const { data: fleetGeofences, loading: fleetGeofencesLoading } = useQ(GetFleetGeofencesDoc, {
    skip: !driverDetails.user.fleets.length,
    variables: {
      fleetId: driverDetails.user.fleets[0].id,
      withSubfleets: false,
      withParentFleets: true,
    },
  });

  const dedupedGeofences = useMemo(() => {
    if (fleetGeofences && driverDetails) {
      const { geofences } = driverDetails;
      return arrayDedupeOnKey([...fleetGeofences, ...geofences], 'id');
    }
    return [];
  }, [fleetGeofences, driverDetails]);

  const {
    loading,
    error,
    data: [activityHistory] = [],
  } = useQ(GetDriverActivityHistoryDoc, {
    variables: {
      driverId: driverId!,
      offset: currentPageOffset,
      fromDate: dateFromFilterState?.toISOString(),
      toDate: dateToFilterState?.toISOString(),
      sortType: sortTypeState,
      include: eventTypeFilterState.length ? eventTypeFilterState.map((x) => eventTypeToIncludeTypeMap[x]) : undefined,
      geofenceIds: getGeofenceIdFilter(
        geofenceBreachFilterState,
        dedupedGeofences.map((x) => x.id),
      ),
      tripsWithLongIdling: tripLongIdlingFilterState,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setTripIds(
      activityHistory?.activity.data
        .filter((x) => x.__typename.includes('TripEvent'))
        ?.map((x) => x.messageId)
        .reverse(),
    );
  }, [activityHistory?.activity, setTripIds]);

  const onDateFilterSelectPeriod = (item: ActivityHistoryDateFilterOption) => {
    if (item.value === 'custom') {
      setPeriodFilterState(undefined);
      resetPageOffsets();
      return;
    }
    const period = item.value as Period;
    setPeriodFilterState(period);
    setDateFromFilterState(periodStartToDate(period));
    setDateToFilterState(endOfDay(new Date()));
    resetPageOffsets();
  };

  const onSelectGeofenceFilter = (item: SelectOption<GeofenceBreachFilterType | string>) => {
    setGeofenceBreachFilterState(item.value);
  };

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { error_text },
    },
  } = i18nContext;

  if (error) return <div className="h-full">{error_text}</div>;

  const {
    activity: { count, data: activityHistoryData },
  } = activityHistory ?? { activity: { count: 0, data: [] } };

  const geofenceBreachEvents: DriverGeofenceBreachEvent[] = activityHistoryData.filter(
    (x) => x.__typename === 'DriverGeofenceBreachEvent' && !!x.tripId,
  ) as DriverGeofenceBreachEvent[];

  const tripIdToGeofenceBreachMap: Record<string, DriverGeofenceBreachEvent[]> = geofenceBreachEvents.reduce(
    (acc, curr) => {
      if (curr.tripId && !acc[curr.tripId]) {
        acc[curr.tripId] = [];
      }
      if (curr.tripId) {
        acc[curr.tripId].push(curr);
      }

      return acc;
    },
    {} as Record<string, DriverGeofenceBreachEvent[]>,
  );

  const longIdlingEvents: LongIdleEvent[] = activityHistoryData.filter(
    (x) => x.__typename === 'LongIdleEvent' && !!x.longIdleTripId,
  ) as LongIdleEvent[];

  const tripIdToLongIdleTripIdMap: Record<string, LongIdleEvent[]> = longIdlingEvents.reduce((acc, curr) => {
    if (curr.tripId && !acc[curr.tripId]) {
      acc[curr.tripId] = [];
    }
    if (curr.tripId) {
      acc[curr.tripId].push(curr);
    }

    return acc;
  }, {} as Record<string, LongIdleEvent[]>);

  const allEvents: DriverActivityEventItem[] = activityHistoryData
    .map((event) => {
      switch (event.__typename) {
        case 'DriverTripEvent': {
          return {
            ...event,
            geofenceNotifications: tripIdToGeofenceBreachMap[event.messageId],
            longIdlingEvents: tripIdToLongIdleTripIdMap[event.messageId],
          } as Partial<DriverTripEvent> & {
            geofenceNotifications: Partial<DriverGeofenceBreachEvent>[];
            longIdlingEvents: Partial<LongIdleEvent>[];
          };
        }
        case 'DriverGeofenceBreachEvent': {
          return undefined;
        }
        case 'LongIdleEvent': {
          return undefined;
        }
        default: {
          return event;
        }
      }
    })
    .filter(isDefined);

  return (
    <>
      <ActivityHistoryList
        currentPageOffsetState={currentPageOffsetState}
        setCurrentPageOffsetState={setCurrentPageOffsetState}
        showPaginationForwards={count > currentPageOffset + maxItemsPerPage}
        showPaginationBack={currentPageOffsetState.length > 0}
        displayedEvents={allEvents}
        nextPageOffset={currentPageOffset + maxItemsPerPage}
        sortTypeState={sortTypeState}
        dataLoading={
          loading ||
          driverDetailsLoading ||
          fleetGeofencesLoading ||
          !driverDetails ||
          !fleetGeofences ||
          !activityHistory
        }
        filters={[
          <ListSort key="sort" currentSortType={sortTypeState} onSelect={(item) => setSortTypeState(item.value)} />,
          <DateFilter
            key="date_filter"
            currentlySelectedPeriod={periodFilterState}
            onSelectPeriod={onDateFilterSelectPeriod}
            fromDateFilter={{
              currentValue: dateFromFilterState,
              onChange: (dateFrom) => {
                setDateFromFilterState(dateFrom);
                resetPageOffsets();
              },
            }}
            toDateFilter={{
              currentValue: dateToFilterState,
              onChange: (dateTo) => {
                setDateToFilterState(endOfDay(dateTo));
                resetPageOffsets();
              },
            }}
          />,
          <EventTypeFilter
            key="event_type_filter"
            eventTypes={values(DriverActivityEventType)}
            currentlySelectedEventTypes={eventTypeFilterState}
            setEventTypeItems={(items) => setEventTypeFilterState(items)}
          />,
          <GeofenceBreachFilter
            key="geofence_filter"
            disabled={!eventTypeFilterState.includes(DriverActivityEventType.TRIP)}
            geofences={dedupedGeofences}
            onSelect={onSelectGeofenceFilter}
          />,
          <TripLongIdlingFilter
            key="trip_long_idling_filter"
            disabled={!eventTypeFilterState.includes(DriverActivityEventType.TRIP)}
            onChange={setTripLongIdlingFilterState}
          />,
        ]}
      />
    </>
  );
};
