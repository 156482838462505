import {
  DriverDeviceDiagnosticEvent,
  DriverGeofenceBreachEvent,
  DriverImpactEvent,
  DriverTripEvent,
  LongIdleEvent,
} from 'generated/graphql';

export enum DriverActivityEventType {
  TRIP = 'trip',
  IMPACT = 'impact',
  DISCONNECTED = 'disconnected',
  RECONNECTED = 'reconnected',
}

export type DriverActivityEventItem =
  | (Partial<DriverTripEvent> & {
      geofenceNotifications: Partial<DriverGeofenceBreachEvent>[];
      longIdlingEvents: Partial<LongIdleEvent>[];
    })
  | Partial<DriverImpactEvent>
  | Partial<DriverDeviceDiagnosticEvent>;
